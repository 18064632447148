<template src="./RecoveryAtIdE.html">
</template>

<script>
import firstAdmissionService from '@/services/FirstAdmission';

export default {
  name: 'recovery-at-id-e',
  data () {
    return {
      securityImages: null,
      securityWord: null,
      selectedWord: null,
      selectedImage: null,
      counter: 0
    }
  },
  methods: {
    redirectTo(route) {
      this.$router.push({path: `${route}`})
    },
    postSecurityImageAndWord() {
      const securityData = {
        imagen: this.selectedImage,
        word: this.selectedWord
      }
      firstAdmissionService.postImageAndWordValidation(securityData)
        .then(response => {
          this.counter = this.counter + 1;
          if (this.counter === 3  && response.validate === false) {
            this.$router.push({path: '/recuperatiderror'})
          } else if (response.validate === true) {
            this.$router.push({path: '/recuperatidexito'})
          }
        })
        .catch((e) => {
          console.error('error', e)
        })
    }
  },
  created () {
    firstAdmissionService.getImageAndWordToValidate()
      .then(response => {
        this.securityImages = response.imagenesSeguridad;
        this.securityWord = response.palabrasSeguridad;
      })
      .catch((e) => {
        console.error('error', e)
      })
  },
  computed: {
    disableButton() {
      return this.selectedImage && this.selectedWord;
    }
  }
}
</script>

<style lang="scss" scoped src="./RecoveryAtIdE.scss">
</style>
